const d = document;

const initializeSlider = (sliderContainer) => {
  const slides = sliderContainer.querySelectorAll('.slide-content');
  const images = sliderContainer.querySelectorAll('.slide-image');
  const totalSlides = slides.length;
  if (totalSlides === 0) {
    return {};
  }
  const transitionTime = parseFloat(sliderContainer.dataset.transitionTime) || 3000;
  let currentSlide = 0;
  let sliderTimeout;
  let activeBar;
  let isSliderStopped = false;
  let hasInsideClickOccurred = false;

  const updateSlide = (index = -1, clicked = false) => {
    clearTimeout(sliderTimeout);
    if (index !== -1) {
      currentSlide = index;
    }

    slides.forEach((slide) => slide.classList.remove('active'));
    slides[currentSlide].classList.add('active');

    const resetBars = () => {
      sliderContainer.querySelectorAll('.progress-bar').forEach((bar) => {
        bar.style.transition = 'none';
        bar.style.height = '0%';
      });
    };

    const startBarTransition = () => {
      activeBar = slides[currentSlide].querySelector('.progress-bar');
      activeBar.style.transition = 'none';
      if (clicked || isSliderStopped) {
        activeBar.style.height = '100%';
      } else {
        activeBar.style.height = '0%';
        requestAnimationFrame(() => {
          activeBar.style.transition = `height ${transitionTime}ms linear`;
          activeBar.style.height = '100%';
        });
      }
    };

    const updateImages = () => {
      images.forEach((image) => (image.style.display = 'none'));
      images[currentSlide].style.display = 'block';
    };

    resetBars();
    startBarTransition();
    updateImages();

    if (!isSliderStopped && !clicked) {
      sliderTimeout = setTimeout(() => {
        currentSlide = (currentSlide + 1) % totalSlides;
        updateSlide();
      }, transitionTime);
    }
  };

  const stopSliderAndSwitch = (index) => {
    isSliderStopped = true;
    hasInsideClickOccurred = true;
    clearTimeout(sliderTimeout);
    updateSlide(index, true);
  };

  const restartSlider = () => {
    if (hasInsideClickOccurred) {
      isSliderStopped = false;
      hasInsideClickOccurred = false;
      currentSlide = 0;
      updateSlide(0);
    }
  };

  updateSlide();

  // Attach click events to each slide content
  slides.forEach((slide, index) => {
    slide.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      stopSliderAndSwitch(index);
    });
  });

  // Add click event listener to the slider container to restart the slider
  sliderContainer.addEventListener('click', (e) => {
    // Check if the click is outside of any slide but inside the container
    if (!Array.from(slides).some((slide) => slide.contains(e.target))) {
      restartSlider();
    }
  });

  return {
    updateSlide,
    stopSliderAndSwitch,
    restartSlider
  };
};

// Initialize all sliders on the page
d.querySelectorAll('.slider-container').forEach((container) => {
  initializeSlider(container);
});